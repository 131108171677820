import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import splashvideowebm from "../assets/video/logo.webm";
import splashvideomp4 from "../assets/video/logo.mp4";

const Splash = ({ onEnd, message }) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.getElementById("end")?.click();
    }, 6000);

    return () => clearTimeout(timeoutId); // Clean up the timeout on unmount
  }, []);

  return (
    <Container
      fluid
      className="p-0 min-vh-100 d-flex flex-column justify-content-center align-items-center"
    >
      <button id="end" onClick={onEnd} className="d-none"></button>
      <Row className="justify-content-center align-items-center w-100">
        <Col
          xs={12}
          md={8}
          lg={6}
          className="d-flex flex-column align-items-center"
        >
          <video
            onEnded={onEnd}
            id="vid"
            className="w-100 h-auto max-width-100"
            autoPlay
            muted
            playsInline
          >
            <source src={splashvideowebm} type="video/webm" />
            <source src={splashvideomp4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {message && (
            <div className="text-white mt-1 text-center ">
              <p>{message}</p>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Splash;
