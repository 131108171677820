import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Loading from "./components/Loading";
import Splash from "./components/Splash"; // Import Splash component
import Join from "./pages/Join";

const App = () => {
  const [config, setConfig] = useState({
    splash: { splash_show: false, message: "" }, // true: show spalash , false: spalsh not showing
    pause: false,
    auth_choose_title: false,
  });
  const [loading, setLoading] = useState(true);
  const [showSplash, setShowSplash] = useState(true);

  const handleSplashEnd = () => {
    setShowSplash(false);
  };

  if (showSplash || config.pause) {
    return <Splash onEnd={handleSplashEnd} message={config.splash.message} />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Join config={config} />} />
        <Route path="*" element={<Navigate to="/" />} /> {/* Default route */}
      </Routes>
    </>
  );
};

export default App;
