import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import ErrorBox from "../utility/ErrorBox";
import { Spinner } from "react-bootstrap";
import Info from "../utility/Info";
import "../css/join.css";
import axios from "axios";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptData = (text) => {
  return CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
};

const Join = ({ config }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading
  const [invitationCode, setInvitationCode] = useState(""); // State for input value
  const [currentBorder, setCurrentBorder] = useState(0); // Keeps track of the current border being blinked
  const [blinks, setBlinks] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Stores the number of blinks for each border
  const maxBlinks = 3;
  const [allBordersOn, setAllBordersOn] = useState(false); // Flag to check if all borders are on

  useEffect(() => {
    const interval = setInterval(() => {
      setBlinks((prevBlinks) => {
        const newBlinks = [...prevBlinks];

        if (!allBordersOn) {
          // Increase blinks for the current border
          if (newBlinks[currentBorder] < maxBlinks) {
            newBlinks[currentBorder]++;
          } else {
            // Stop blinking and move to the next border if current border reached max blinks
            newBlinks[currentBorder] = maxBlinks; // Ensure the current border stays green
            const nextBorder = (currentBorder + 1) % 8; // Move to the next border
            setCurrentBorder(nextBorder);
          }
        } else {
          // Once all borders are on, turn them off one by one in reverse order
          if (newBlinks[currentBorder] > 0) {
            newBlinks[currentBorder]--;
          } else {
            // Once a border is turned off, move to the previous one
            const prevBorder = (currentBorder - 1 + 8) % 8;
            setCurrentBorder(prevBorder);
          }
        }

        // Check if all borders are on
        if (newBlinks.every((blink) => blink === maxBlinks)) {
          setAllBordersOn(true); // Set flag to true when all borders are on
        }

        // If all borders are turned off, reset everything to start the cycle again
        if (newBlinks.every((blink) => blink === 0)) {
          setBlinks([0, 0, 0, 0, 0, 0, 0, 0]); // Reset blinks to start over
          setCurrentBorder(0); // Reset the current border to the start
          setAllBordersOn(false); // Reset the "all borders on" flag
        }
        return newBlinks;
      });
    }, 100); // Update every 500ms

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [currentBorder, allBordersOn]);

  const handleCheckClick = async () => {
    const encryptedCode = encryptData(invitationCode);

    setLoading(true); // Start loading
    setErrorMessage(null);
    try {
      const response = await axios.post(
        "https://api.the369.xyz/verify-invite",
        {
          code: encryptedCode,
        }
      );

      // This will only happen if the request is successful
      setErrorMessage(response.data.message); // Always "Wrong code" or whatever message you get from the server
    } catch (error) {
      if (error.response) {
        if (error.response.status === 429) {
          // Rate limit exceeded error
          setErrorMessage("Too many requests. Please try again later.");
        } else {
          // Other server errors (not rate-limited)
          setErrorMessage("Error contacting server");
        }
      } else {
        // Handle network or other non-response related errors
        setErrorMessage("Error contacting server, Too many requests.");
      }
    } finally {
      setLoading(false); // Hide loading indicator when done
    }

    // Simulate an async operation (e.g., API call)
    // setTimeout(() => {
    //   setLoading(false); // Stop loading after 2 seconds (simulate the process)
    //   setErrorMessage("Wrong code, try again...");
    // }, 2000);
  };

  // Add class conditionally based on blink count
  const getBorderClass = (index) => {
    if (blinks[index] === maxBlinks) {
      if (errorMessage) {
        return "border-success border rounded p-3 border-3";
      } else {
        return "border-success border rounded p-3 border-3"; // Green color when blinking ends
      }
    } else if (blinks[index] > 0) {
      return ""; // Add a blinking class when the border is blinking
    } else {
      return ""; // Default border color
    }
  };

  return (
    <>
      <Container className="d-flex justify-content-center main-page flex-column">
        <div className="p-3">
          <div className={`${getBorderClass(6)} p-4 `}>
            <div className={` ${getBorderClass(5)} p-4 `}>
              <div className={` ${getBorderClass(4)} p-4 `}>
                <div className={` ${getBorderClass(3)} p-4 `}>
                  <div className={` ${getBorderClass(2)} p-4 `}>
                    <div className={` ${getBorderClass(1)} p-4 `}>
                      <div className={` ${getBorderClass(0)} p-4 `}>
                        <Row className="justify-content-evenly main-content square-row">
                          <Col xs={12}>
                            <div className="p-4">
                              <h4 className="text-center text-light">
                                {config.auth_choose_title}
                              </h4>
                              {/* Invitation Code Input Form */}
                              <Form className="text-center">
                                <span className="text-white">
                                  Enter the code{" "}
                                  <Info
                                    data={
                                      "An invitation code is required to sign up. Follow the signs..."
                                    }
                                    css={"fs-5"}
                                  />
                                </span>
                                <Form.Group className="mb-2 mt-2">
                                  <Form.Control
                                    type="text"
                                    placeholder="Invitation code"
                                    className={
                                      "bg-dark text-white border-light custom-input"
                                    }
                                    value={invitationCode}
                                    onChange={(e) =>
                                      setInvitationCode(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleCheckClick(); // Trigger the button click when Enter is pressed
                                      }
                                    }}
                                    disabled={loading} // Disable the input when loading
                                  />
                                </Form.Group>
                                <div className="d-flex justify-content-between mt-2">
                                  <Button
                                    onClick={handleCheckClick}
                                    className="auth-button d-flex align-items-center ms-auto me-auto mt-3"
                                    disabled={loading} // Disable the button while loading
                                  >
                                    {loading ? (
                                      <Spinner
                                        animation="border"
                                        size="sm"
                                        className="me-2"
                                      />
                                    ) : (
                                      <i className="bi bi-check-circle-fill fs-4"></i>
                                    )}
                                    <span className="ms-2">
                                      {loading ? "Checking..." : "Check"}
                                    </span>
                                  </Button>
                                </div>
                              </Form>

                              {/* OR Line */}
                              <div className="text-center my-3 position-relative">
                                <hr className="my-4 border-white border-3" />
                                <span className="position-absolute top-50 start-50 translate-middle px-2 bg-dark text-light rounded">
                                  OR
                                </span>
                              </div>
                              {/* Disabled Sign In Button */}
                              <div className="text-center mt-3">
                                <Button
                                  variant="secondary"
                                  className="auth-button d-flex align-items-center mx-auto"
                                  disabled
                                >
                                  <i className="bi bi-person-fill-check fs-4"></i>
                                  <span className="ms-2">Sign In</span>
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorBox error={errorMessage} onClose={() => setErrorMessage(null)} />
      </Container>
    </>
  );
};

export default Join;
