import React, { useState, useRef } from "react";
import { Tooltip, Overlay } from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const Info = ({ data, css }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = (e) => {
    clearTimeout(timeoutRef.current); // Clear any existing timeout
    if (!showTooltip) {
      setTarget(e.target);
      setShowTooltip(true);
    }
  };

  const handleClick = (e) => {
    clearTimeout(timeoutRef.current); // Clear any existing timeout
    setTarget(e.target);
    setShowTooltip(!showTooltip);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 5000); // Close tooltip after 6 seconds
  };

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{ position: "relative", display: "inline-block" }}
      onMouseLeave={handleMouseLeave} // Trigger hide countdown on mouse leave
    >
      <AiOutlineQuestionCircle
        onMouseEnter={handleMouseEnter}
        onClick={handleClick}
        className={`"c-pointer" ${css}`}
        style={{ cursor: "pointer" }}
      />
      <Overlay target={target} show={showTooltip} placement="top">
        {(props) => (
          <Tooltip id="info-tooltip" {...props}>
            {data}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default Info;
