import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { BiSolidError } from "react-icons/bi";
import "../css/errorBox.css"; // Import CSS file

const ErrorBox = ({ error, onClose }) => {
  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (error) {
      setShake(true);
      const timeout = setTimeout(() => setShake(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [error]);

  if (!error) return null;

  return (
    <Card
      className={`text-danger bg-dark border-danger mt-3 position-relative p-2 ${
        shake ? "shake-scale" : ""
      }`}
    >
      <Card.Body>
        <div className="d-flex align-items-center me-5">
          <span>{error}</span>
        </div>

        {/* Error Icon - Bottom Right */}
        <span className="position-absolute bottom-0 end-0 p-2">
          <BiSolidError className="fs-4 text-danger" />
        </span>

        {/* Close Button - Top Right */}
        <Button
          variant="transparent"
          size="sm"
          className="position-absolute top-0 end-0 m-2 text-light"
          onClick={onClose}
          style={{ fontSize: "12px" }}
        >
          ✖
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ErrorBox;
